import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import axios from '@/services/base'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    status: '',
    token: '',
    user: {
      id: '',
      name: '',
      email: '',
      steamid64: '',
      steam_url: ''
    },
  },
  mutations: {
    login(state, token, user) {
      state.status = 'success'
      state.token = token
      state.user = user
    },
    auth_success(state, token, user) {
    },
    update_user(state, user) {
      state.user = user
    },
    auth_error(state) {
      state.status = 'error'
    },
    redirecting_to_login(state) {
      state.status = 'redirecting'
    },
    updateUser(state, user) {
      state.user = user
    },
    logout(state) {
      state.status = ''
      state.token = ''
      state.user = {
        id: '',
        name: '',
        email: '',
        steamid64: '',
        steam_url: ''
      }
    },
  },
  actions: {
    login({ commit }, data) {
      commit('login', data.token)
      commit('updateUser', data.user)
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        commit('logout')
        localStorage.removeItem('token');
        delete axios.defaults.headers.common['Authorization']
        resolve()
      })
    },
    updateUserProfile({ commit }, user) {
      commit('update_user', user)
    },
    redirectingToLogin({ commit }) {
      commit('redirecting_to_login')
    },
  },
  getters: {
    isLoggedIn: state => !!state.token,
    isAdmin: state => state.token && state.user && state.user.role == 'role_user_admin',
    token: state => state.token,
    authStatus: state => state.status,
    user: state => state.user
  },
  plugins: [createPersistedState({ key: 'fightback-frontend' })]
})
