module.exports = {
  pt_BR: {
    NAV_HEADER: {
      back_to_home: "voltar para a página inicial",
      navigation_links: {
        my_matches: "Minhas Partidas",
        matches: "Partidas Públicas",
        collections: "Collections",
        teams: "Times",
        search: "Busca",
        notifications: "Notificações",
        my_profile: "Meu Perfil",
        edit_profile: "Editar Perfil",
        plan: "Meu plano",
        logout: "Sair",
        login: "Entrar",
        upgrade: 'Nossos planos / Contrate'
      }
    },
    FOOTER: {
      to_players: {
        title: "Para jogadores",
        gc: "Integração com Gamersclub",
        hltv: "Integração com HLTV"
      },
      to_teams: {
        title: "Para times",
        live: "Análise ao vivo"
      },
      to_companies: {
        title: "Para empresas",
        embed: "Player Embed",
        integration: "Integração pós-jogo",
        live: "Análise ao vivo"
      },
      about: {
        title: "Sobre",
        about: "Sobre",
        contact: "Contato",
        jobs: "Trabalhe conosco",
        privacy: "Privacidade"
      }
    },
    PAGE_LOGIN: {
      main_title: "Melhore no CS:GO",
      main_subtitle: "Revendo suas partidas e análise direto no navegador",
      integrations_title: "Funciona com o que você já usa",
      login_title: "Já tem conta? Entrar",
      login_submit: "Entrar",
      email_placeholder: "Email cadastrado",
      password_placeholder: "Sua senha",
      non_user_title: "Ainda não é cadastrado?",
      non_user_signup_now: "Cadastre-se agora",
      features: {
        title: "Ajudamos você a entender",
        clutch: {
          title: "Comportamento em Clutches",
          subtitle: "Como jogar em (des)vantagem"
        },
        grenades: {
          title: "Granadas Efetivas",
          subtitle: "ou estou apenas gastando dinheiro?"
        },
        playstyle: {
          title: "Agressivo x Defensivo",
          subtitle: "Qual estilo de jogo funciona melhor?"
        }
      },
      search: {
        title: "Busca Inteligente",
        subtitle: "Encontre aquele round que precisa",
        line1: "Com os filtros você consegue buscar exatamente o round que procura.",
        line2: "Escolha o mapa, se é eco, forçado, e navegue por tags."
      },
      extension: {
        title: "Extensão para o Chrome",
        subtitle: "Reveja partidas de CS:GO direto no navegador",
        line1: "Você não precisa baixar a demo dos sites e upar no FIGHTBACK.",
        line2: "Com a extensão tudo fica mais fácil.",
        see_more: "Saiba mais"
      },
      comments: {
        title: "Comentários",
        subtitle: "Analisar e aprender coletivamente é mais fácil",
        line1: "Com nossos comentários por round ninguém fica de fora do aprendizado.",
        line2: "Converse com o seu time, seus amigos ou com toda a comunidade."
      },
      plans: {
        title: "Planos",
        subtitle: "Preparamos planos específicos para cada perfil"
      }
    },
    MATCHES: {
      actions: {
        edit_match: 'editar partida',
        delete_match: 'apagar partida',
        add_to_collection: 'salvar a Collection',
        share_match: 'compartilhar',
        download_match: 'baixar demo'
      },
      parsing_alert: {
        title: 'Estamos processando essa partida',
        subtitle: 'Atualize essa página em 2 minutos...'
      },
      tabs: {
        dashboard: 'Visão Geral',
        economy: 'Economia',
        watch: 'Assistir Rounds'
      },
      economy: {
        total_spent: 'dinheiro gasto',
        start_money: 'equip. início',
        economy_vs_rounds: 'economia X rounds ganhos',
      },
      share_modal: {
        title: 'compartilhar',
        email_placeholder: 'informe o email de com quem deseja compartilhar',
        submit: 'compartilhar'
      },
      tags_modal: {
        title: 'editar tags',
        common_tags: 'tags comuns',
        submit: 'salvar'
      },
      edit_modal: {
        title: 'editando',
        name_placeholder: 'digite um nome para identificar',
        unique_code_placeholder: 'codigo unico (n mexa se nao sabe)',
        is_public_label: 'Pública?',
        submit: 'Salvar '
      }
    },
    MY_MATCHES: {
      title: "Minhas Partidas",
      send_match: "Enviar uma partida",
      total_sent_matches: "Partidas enviadas",
      remaining_matches: 'Você ainda tem {count} partidas neste mês',
      matches_full: "Você não tem mais partidas disponíveis."
    },
    MATCH_NEW: {
      title: 'Envie uma partida',
      subtitle: 'Faça o upload de sua partida <strong>formato .dem</strong> abaixo'
    },
    ROUND_SINGLE: {
      report_round_title: 'Problemas ao ver esse round?',
      report_round: 'reportar',
      related_rounds: 'rounds relacionados',
      comments: {
        title: 'comentários',
        no_comments: 'nenhum comentário, seja o primeiro',
        comment_placeholder: 'escreva seu comentário aqui...',
        submit: 'comentar',
        logged_out_warning: 'você precisa estar logado para comentar'
      },
      actions: {
        back_to_match: 'voltar a partida',
        add_to_collection: 'adicionar a Collection',
        share: 'compartilhar'
      },
      report_modal: {
        modal_title: 'reportar round',
        title: 'problemas com esse round?',
        subtitle: 'Caso não esteja conseguindo rever este round ou ocorra algum outro problema, digite o motivo no campo abaixo e clique em reportar',
        text_placeholder: 'digite aqui o que está acontecendo',
        submit: 'reportar'
      },
      share_modal: {
        modal_title: 'compartilhe este round',
        title: 'embed em qualquer site',

      }
    },
    COLLECTIONS: {
      title: "Collections",
      subtitle: "Usando Collections você consegue organizar suas partidas e rounds favoritos em uma playlist.",
      create: "criar uma collection",
      modal: {
        title: 'Criar Collection',
        name: 'Nome da Collection',
        name_placeholder: 'Escolha um nome',
        description: 'Descrição',
        description_placeholder: 'Do que se trata essa collection? ex: rounds pistol em um mapa? jogos de um campeonato? rounds forçados?',
        is_public: 'Pública?',
        is_public_description: 'selecionando estará disponível para todos os usuários do FIGHTBACK, caso contrário apenas para você e quem convidar',
        is_public_label: 'Collection Pública',
        submit: 'Criar'
      },
      table_title: 'Collections do FIGHTBACK.club',
      created_by: 'criado por'
    },
    TEAMS: {
      title: 'Times',
      create_team: 'criar time',
      modal: {
        title: 'criando time',
        name_placeholder: 'informe um nome',
        submit: 'criar'
      }
    },
    SEARCH: {
      title: 'Busca',
      subtitle: 'É aqui onde tudo faz sentido. Filtre por mapas ou tags em TODAS AS PARTIDAS',
      no_rounds_found: 'nenhum round encontrado',
      collection_modal: {
        title: 'adicionar a uma Collection',
        match: 'adicionar partida a Collection',
        match_round: 'adicionar round a Collection',
        name_placeholder: 'digite um nome para identificar',
        submit: 'Salvar'
      },
      filters: {
        my_matches_only: "Buscar apenas em minhas partidas",
        tags: "filtre por tags",
        tags_title: "clique para adicionar uma tag aos filtros",
        maps: "filtre por mapa",
        maps_title: "clique em um mapa abaixo para filtrar todos os rounds apenas no mapa selecionado",
        economy: "filtre por economia",
        economy_title: "clique em um mapa abaixo para filtrar por economia no round",
        trouble: "de qual time?",
        trouble_title: "clique em um mapa abaixo para filtrar pelo time nesta economia"
      }
    },
    NOTIFICATIONS: {
      title: 'Notificações',
    },
    USERS: {
      about: 'Sobre',
      collections: 'Collections',
      latest_rounds: 'Últimos rounds jogados',
      change_avatar: 'alterar avatar',
      edit_title: 'Edite seu perfil',
      steam_connected: 'conectado com steam',
      steam_connect: 'conectar com steam',
      form: {
        name: 'Nome',
        name_placeholder: 'Informe seu nome',
        nick: 'Apelido / Nick',
        nick_placeholder: 'informe seu nick',
        were_found_us: 'Onde nos conheceu?',
        were_found_us_placeholder: 'site? google? redes sociais? outro?',
        profile_extra_a: 'Com quem irá usar o FIGHTBACK.club?',
        alone: 'Sozinho',
        with_team: 'Com meu time',
        submit: 'Salvar'
      }
    },
    ACCOUNT: {
      title: 'Minha conta',
      no_plan: 'Você não tem nenhum plano ativo',
      current_plan: 'Atualmente você está no plano <strong>{package}</strong>',
      expires_at: 'Expira em {at}',
      needs_update: {
        title: 'Antes de contratar um plano você precisa preencher detalhes do seu perfil',
        subtitle: 'Completar cadastro',
        modal: {
          title: 'Dados pessoais para Nota Fiscal',
          cpf: 'seu cpf',
          cellphone: 'seu celular',
          submit: 'salvar e prosseguir'
        }
      },
      no_premium: {
        title: 'Seja PREMIUM e tenha acesso imediato a',
        private_upload: 'Upload de <strong>100 partidas privadas</strong> por mês',
        private_share: 'Compartilhar partidas privadas com outros usuários (seu time por exemplo)',
        collections: '<strong>Collections</strong> para você salvar todos os rounds e partidas em listas',
        cta: 'Seja PREMIUM agora por R$ 29,90'
      },
      subscriptions: {
        title: 'suas assinaturas',
        created_at: 'Contratado em',
        status: 'Situação',
        ends_at: 'Encerra em',
        pay_now: 'Pagar agora'
      }
    },
    HOME: {
      welcome_alert: {
        title: 'Bem-vindo ao FIGHTBACK',
        line1: 'Estamos aqui para te ajudar a melhorar no CS:GO',
        line2: 'Separamos algumas partidas para você assistir'
      },
      upload_alert: {
        title: 'Veja suas partidas',
        line1: 'Para começar, envie a demo de uma partida',
        submit: 'Enviar demo'
      }
    },
    ONBOARDING: {
      title: 'Bem-vindo ao FIGHTBACK.club',
      line1: 'Estamos aqui para ajudar você e seu time a melhorar no CS:GO.',
      line2: 'Alguns detalhes antes de continuar:',
      line3: 'Ajuda e novidades é pelo <a href="https://discord.gg/8HJMgG4" target="_blank">nosso Discord</a>. Entra lá :)',
      line4: 'Preencha seu perfil e conecte com a Steam - teremos novidades em breve ;)',
      line5: 'De coração, obrigado mais uma vez.'
    },
    SUPPORT_WIDGET: {
      support_label: 'suporte',
      whatsapp_label: 'Mande mensagem no Whats',
      discord_label: 'Fale conosco no Discord',
      modal_title: 'Precisando de ajuda?'
    },
    UTILITY: {
      better_on_mobile: "Nosso site funciona melhor ainda no computador",
      unnamed: 'sem nome',
      match: "Partida",
      matches: "Partidas",
      map: "Mapa",
      added_at: "Adicionado em",
      round: 'Round',
      winner: 'Vencedor',
      timeline: 'Timeline',
      tags: 'Tags',
      see_match: 'ver partida',
      here: 'aqui',
      latest_comments: 'últimos comentários',
      by: 'por',
      at: 'em',
      trending_rounds: 'rounds em alta',
      team_a: 'team A',
      team_b: 'team B',
      share: 'compartilhar'
    },

    REASON_CODES: {
      forgot_password_used: "Este token já foi utilizado"
    }
  }
}
