export default {
  computed: {
    isLoggedIn: function() {
      return this.$store.getters.isLoggedIn;
    },
    isAdmin: function() {
      return this.$store.getters.isAdmin;
    },
    authStatus: function() {
      return this.$store.getters.authStatus;
    },
    currentUser: function() {
      return this.$store.getters.user;
    },
    isLocal: function() {
      return window.location.hostname == "fightback.club";
    },
    isMobile: function() {
      var Android = navigator.userAgent.match(/Android/i);
      var BlackBerry = navigator.userAgent.match(/BlackBerry/i);
      var iOS = navigator.userAgent.match(/iPhone|iPad|iPod/i);
      var Opera = navigator.userAgent.match(/Opera Mini/i);
      var Windows = navigator.userAgent.match(/IEMobile/i);
      var any = (Android || BlackBerry || iOS || Opera || Windows);
      var none = (!any);
      return any;
    }
  }
};
