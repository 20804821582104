import Vue from 'vue'

// Set global variables - base url for dev/prod
var APP_URL = (window.location.hostname == 'fightback.club') ? 'https://fightback.club' : 'http://localhost:8080'
var API_URL = (window.location.hostname == 'fightback.club') ? 'https://api.fightback.club' : 'http://localhost:5000'
var PLAYER_URL = (window.location.hostname == 'fightback.club') ? 'https://player.fightback.club' : 'https://localhost:1337'
Vue.prototype.$appURL = APP_URL
Vue.prototype.$apiURL = API_URL
Vue.prototype.$playerURL = PLAYER_URL


import store from './store'
import router from './router'

import BootstrapVue from 'bootstrap-vue'
Vue.use(BootstrapVue)

import Notifications from 'vue-notification'
Vue.use(Notifications)

import VuePageTransition from 'vue-page-transition'
Vue.use(VuePageTransition)

import Clipboard from 'v-clipboard'
Vue.use(Clipboard)

import VueAhoyjs from './vue-ahoyjs'
Vue.use(VueAhoyjs, { urlPrefix: API_URL }, router)

import VueCurrencyFilter from 'vue-currency-filter'
Vue.use(VueCurrencyFilter, {
  symbol: 'R$',
  thousandsSeparator: ',',
  fractionCount: 2,
  fractionSeparator: '.',
  symbolPosition: 'front',
  symbolSpacing: true
})

import VueI18n from 'vue-i18n'
import ptBrMessages from './translations/pt_BR'
import enMessages from './translations/en'
Vue.use(VueI18n)
const i18n = new VueI18n({ locale: 'pt_BR', messages: {
  en: enMessages.en,
  pt_BR: ptBrMessages.pt_BR
} })

import ActionCable from 'actioncable'
Vue.prototype.$cable = {}

import DefaultLayout from './layouts/Default.vue';
Vue.component('default-layout', DefaultLayout);

import AuthLayout from './layouts/Auth.vue';
Vue.component('auth-layout', AuthLayout);

import MarketingLayout from './layouts/Marketing.vue';
Vue.component('marketing-layout', MarketingLayout);

import './registerServiceWorker.js'

import Auth from './mixins/auth.mixin'
Vue.mixin(Auth)
import Helpers from './mixins/helpers.mixin'
Vue.mixin(Helpers)

import VueAnalytics from 'vue-analytics'
Vue.use(VueAnalytics, {
  id: 'UA-19971072-19',
  router,
  autoTracking: {
    pageviewTemplate(route) {
      return {
        page: route.path,
        title: document.title,
        location: window.location.href
      }
    }
  },
  debug: {
    sendHitTask: window.location.hostname == 'fightback.club'
  }
})

if (store.getters.isLoggedIn) {
  if (FS !== undefined && window.location.hostname == 'fightback.club')
    FS.identify(store.getters.user.id, {
      displayName: store.getters.user.email,
      email: store.getters.user.email,
    })

  Vue.prototype.$cable = ActionCable.createConsumer(API_URL + "/cable?token=" + store.getters.token);
}


import './scss/main.sass'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import 'nprogress/nprogress.css'

import App from './App.vue'
import './registerServiceWorker'

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

import VueAxios from 'vue-axios'
import axios from './services/base'
Vue.use(VueAxios, axios)


Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
