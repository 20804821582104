<template>
  <div>
    <div class="container-fluid p-0">
      <div class="row no-gutters">
        <div class="col-12 col-md-4 content-area">
          <slot />
        </div>
        <div class="col-12 col-md-8 side-area d-none d-sm-flex">
          <!-- lado -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AuthLayout",
  metaInfo: {
    bodyAttrs: {
      class: ["auth-layout"],
    },
  },
};
</script>

<style lang="sass" scoped>
.content-area
  background: #000
  height: 100vh

.side-area
  background: #ddd
  height: 100vh

  background: $primary url(/videos/login-background.png) no-repeat
  background-size: cover
  overflow: hidden
</style>
