import ahoy from 'ahoy.js'
import store from '@/store'

export default function install (Vue, options = { }, router) {
  const defaultConfig = {
    urlPrefix: "",
    visitsUrl: "/a/visits",
    eventsUrl: "/a/events",
    withCredentials: false
  }
  // headers: {},
  // visitParams: {},
  // // referring_domain
  // document.referrer;

  let config = Object.assign({}, defaultConfig, options || {});
  ahoy.configure(config);

  if (store.getters.isLoggedIn) ahoy.configure({ headers: { "Authorization": `Bearer ${store.getters.token}` } })
  ahoy.start()
  
  ahoy.debug(false)

  router.afterEach((to, from) => {
    if (store.getters.isLoggedIn) ahoy.configure({ headers: { 'Authorization': `Bearer ${store.getters.token}` } })
    ahoy.trackView();
  })

  Vue.prototype.$ahoy = Vue.$ahoy = window.ahoy = ahoy
}
