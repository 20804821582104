<template>
  <div id="app">
    <component :is="layout">
      <router-view/>
    </component>
  </div>
</template>

<script>
const defaultLayout = "default";

export default {
  name: 'App',
  metaInfo: {
    title: "Melhore seu jogo",
    titleTemplate: "%s | FIGHTBACK.club",
    htmlAttrs: {
      lang: 'pt-BR', // en
      amp: false
    }
  },
  data() {
    return {
    };
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || defaultLayout) + '-layout';
    }
  },
}
</script>

<style lang="scss">
</style>
