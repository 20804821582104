<template>
  <div>
    <b-navbar class="main-navbar" toggleable="md" type="dark" variant="dark">
      <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>

      <b-navbar-brand
        class="navbar-logo"
        to="/"
        exact
        v-b-tooltip.hover
        :title="$t('NAV_HEADER.back_to_home')"
      >
        <b-img src="/fightback-logo-white.png" height="40"></b-img>
      </b-navbar-brand>

      <b-collapse is-nav id="nav_collapse">
        <b-navbar-nav>
          <b-nav-item v-if="isLoggedIn" to="/my_matches">{{
            $t("NAV_HEADER.navigation_links.my_matches")
          }}</b-nav-item>
          <b-nav-item v-if="isLoggedIn" to="/matches">{{
            $t("NAV_HEADER.navigation_links.matches")
          }}</b-nav-item>
          <b-nav-item v-if="isLoggedIn" to="/collections">
            <i class="fas fa-folder"></i>
            {{ $t("NAV_HEADER.navigation_links.collections") }}
          </b-nav-item>
          <b-nav-item to="/teams" v-if="isLoggedIn && isAdmin && false">{{
            $t("NAV_HEADER.navigation_links.teams")
          }}</b-nav-item>
          <b-nav-item v-if="isLoggedIn && false" to="/search">
            <i class="fas fa-search"></i>
            {{ $t("NAV_HEADER.navigation_links.search") }}
          </b-nav-item>
          <b-nav-item
            v-if="
              isLoggedIn && currentUser.subscription_package.package == 'free'
            "
            to="/upgrade"
          >
            <!-- <i class="fas fa-search"></i> -->
            {{ $t("NAV_HEADER.navigation_links.upgrade") }}
          </b-nav-item>
        </b-navbar-nav>

        <b-navbar-nav class="ml-auto">
          <b-nav-item v-if="isLoggedIn" :to="{ name: 'Notifications' }">{{
            $t("NAV_HEADER.navigation_links.notifications")
          }}</b-nav-item>

          <b-nav-item-dropdown right v-if="false">
            <template slot="button-content">
              <em>{{ $i18n.locale }}</em>
            </template>
            <b-dropdown-item
              @click.stop="changeLang(lang)"
              v-for="(lang, i) in langs"
              :key="`Lang${i}`"
              >{{ lang }}</b-dropdown-item
            >
          </b-nav-item-dropdown>

          <b-nav-item-dropdown right v-if="isLoggedIn">
            <template slot="button-content">
              <em v-if="currentUser && currentUser.display_name">{{
                currentUser.display_name
              }}</em>
            </template>
            <b-dropdown-item
              :to="{ name: 'UserSingle', params: { id: currentUser.id } }"
              v-if="currentUser"
              >{{
                $t("NAV_HEADER.navigation_links.my_profile")
              }}</b-dropdown-item
            >
            <b-dropdown-item
              :to="{ name: 'UserEdit', params: { id: currentUser.id } }"
              v-if="currentUser"
              >{{
                $t("NAV_HEADER.navigation_links.edit_profile")
              }}</b-dropdown-item
            >
            <b-dropdown-item :to="{ name: 'Account' }" v-if="currentUser">{{
              $t("NAV_HEADER.navigation_links.plan")
            }}</b-dropdown-item>
            <b-dropdown-item v-on:click.prevent="logout">{{
              $t("NAV_HEADER.navigation_links.logout")
            }}</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item to="/login" v-else>{{
            $t("NAV_HEADER.navigation_links.login")
          }}</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <vue-page-transition name="fade-in-up">
      <slot />
    </vue-page-transition>

    <footer class="bd-footer text-muted">
      <div class="container">
        <footer class="pt-4">
          <div class="row">
            <div class="col-12 col-md">
              <img
                class="mb-2"
                src="/icons/favicon-32x32.png"
                alt
                width="32"
                height="32"
              />
              <small class="d-block mb-3">&copy; 2017-2021</small>
            </div>
            <div class="col-6 col-md">
              <h5>{{ $t("FOOTER.to_players.title") }}</h5>
              <ul class="list-unstyled text-small">
                <li>
                  <a class="text-muted" href="/integracoes/chrome">{{
                    $t("FOOTER.to_players.gc")
                  }}</a>
                </li>
                <li>
                  <a class="text-muted" href="/integracoes/chrome">{{
                    $t("FOOTER.to_players.hltv")
                  }}</a>
                </li>
              </ul>

              <h5>{{ $t("FOOTER.about.title") }}</h5>
              <ul class="list-unstyled text-small">
                <li>
                  <a class="text-muted" href="/about">{{
                    $t("FOOTER.about.contact")
                  }}</a>
                </li>
                <li>
                  <a class="text-muted" href="/privacy-policy">{{
                    $t("FOOTER.about.privacy")
                  }}</a>
                </li>
              </ul>
              <div class="d-none">
                <h5>{{ $t("FOOTER.to_teams.title") }}</h5>
                <ul class="list-unstyled text-small">
                  <li>
                    <a class="text-muted" href="/features/live">{{
                      $t("FOOTER.to_teams.live")
                    }}</a>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-6 col-md">
              <h5>{{ $t("FOOTER.to_companies.title") }}</h5>
              <ul class="list-unstyled text-small">
                <li>
                  <a class="text-muted" href="/features/embed">{{
                    $t("FOOTER.to_companies.embed")
                  }}</a>
                </li>
                <li>
                  <a class="text-muted" href="/features/integration">{{
                    $t("FOOTER.to_companies.integration")
                  }}</a>
                </li>
                <li>
                  <a class="text-muted" href="/features/live">{{
                    $t("FOOTER.to_companies.live")
                  }}</a>
                </li>
              </ul>
            </div>
            <div class="col-6 col-md d-none">
              <h5>{{ $t("FOOTER.about.title") }}</h5>
              <ul class="list-unstyled text-small">
                <li>
                  <a class="text-muted" href="/about">{{
                    $t("FOOTER.about.about")
                  }}</a>
                </li>
                <li>
                  <a class="text-muted" href="/about">{{
                    $t("FOOTER.about.jobs")
                  }}</a>
                </li>
              </ul>
            </div>
          </div>
        </footer>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "MarketingLayout",
  metaInfo: {
    bodyAttrs: {
      class: ["marketing-layout"],
    },
  },
  methods: {
    changeLang(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem("locale", lang);
      document.querySelector("html").setAttribute("lang", lang);
    },
  },
};
</script>
