export default {
  methods: {
    friendlyWinner(winner) {
      switch (winner) {
        case "winner_draw":
          return "empate";
          break;
        case "winner_terrorists":
          return "TR";
          break;
        case "winner_cts":
          return "CT";
          break;
      }
    },

    friendlyType(round_type, side_trouble, team_side) {
      if(side_trouble == 'trouble_none' && round_type == 'round_normal')
        return 'Compra';
      else if(side_trouble.replace('trouble_', '') == team_side)
        switch (round_type) {
          case "round_normal":
            return "Compra";
            break;
          case "round_force_buy":
            return "Forçado";
            break;
          case "round_semi_eco":
            return "Semi eco";
            break;
          case "round_eco":
            return "Eco";
            break;
          case "round_pistol":
            return "Pistol";
            break;
        }
    else
      return "Normal"
    },

    friendlyReason(reason) {
      switch (reason) {
        case "reason_target_bombed":
          return "c4 explodiu";
          break;
        case "reason_bomb_defused":
          return "c4 defusada";
          break;
        case "reason_ct_win":
          return "5 mortes";
          break;
        case "reason_terrorist_win":
          return "5 mortes";
          break;
        case "reason_draw":
          return "empate";
          break;
        case "reason_target_saved":
          return "não plantou c4";
          break;
        case "reason_terrorists_planted":
          return "c4 armada";
          break;
      }
    }
  }
}
