import axios from 'axios'
import store from '@/store'
import router from '@/router'
import NProgress from 'nprogress'
import Vue from 'vue'

var client = axios.create({
  baseURL: (window.location.hostname == 'fightback.club') ? 'https://api.fightback.club' : 'http://localhost:5000',
  headers: {
    'Content-Type': 'application/json'
  }
})
const token = localStorage.getItem('token')
if (token) {
  client.defaults.headers.common['Authorization'] = token
}

const locale = localStorage.getItem('locale')
if (locale) {
  client.defaults.headers.common['Accept-Language'] = locale;
}

client.interceptors.request.use(config => {
  NProgress.start()
  if (store.getters.isLoggedIn) config.headers['Authorization'] = store.getters.token;

  if(Vue.$ahoy) {
    config.headers['Ahoy-Visit'] = Vue.$ahoy.getVisitId()
    config.headers['Ahoy-Visitor'] = Vue.$ahoy.getVisitorId()
  }

  return config
})

client.interceptors.response.use(response => {
  NProgress.done()
  return Promise.resolve(response)
}, error => {
  if (error && error.response && error.response.status && error.response.status === 401) {
    store.dispatch('redirectingToLogin')
    store.dispatch('logout')

    router.push({
      name: 'Login'
    })
    // window.location = error.response.data.location
  } else if (error && error.response && error.response.data && error.response.data.error) {
    Vue.notify({
      group: 'all',
      type: 'warn',
      title: error.response.data.error
    })
    // alert(error.response.data.error)
  }
  NProgress.done()
  return Promise.reject(error)
});
export default client
