import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
// import Home from './views/Home.vue'
import store from '@/store'
import NProgress from 'nprogress'

Vue.use(Router)
Vue.use(Meta)

var router = new Router({
  mode: 'history',
  routes: [{
      path: '/',
      name: 'Home',
      meta: { layout: 'marketing' },
      component: () => import( /* webpackChunkName: "Home" */ './views/Home.vue'),
    },
    {
      path: '/login',
      name: 'Login',
      meta: { layout: 'auth' },
      component: () => import( /* webpackChunkName: "Login" */ './views/Login.vue'),
    },
    {
      path: '/signup',
      name: 'Signup',
      meta: { layout: 'auth' },
      component: () => import( /* webpackChunkName: "Signup" */ './views/Signup.vue'),
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: () => import( /* webpackChunkName: "Dashboard" */ './views/Dashboard.vue'),
      meta: {
        requiresAuth: []
      }
    },
    {
      path: '/my_matches',
      name: 'MyMatchList',
      component: () => import( /* webpackChunkName: "MyMatchList" */ './views/MyMatchList.vue'),
      meta: {
        requiresAuth: []
      }
    },
    {
      path: '/matches',
      name: 'MatchList',
      component: () => import( /* webpackChunkName: "MatchList" */ './views/MatchList.vue'),
      meta: {
        requiresAuth: []
      }
    },
    {
      path: '/matches/new',
      name: 'MatchNew',
      component: () => import( /* webpackChunkName: "MatchNew" */ './views/MatchNew.vue'),
      meta: {
        requiresAuth: []
      }
    },

    {
      path: '/matches/compare',
      name: 'MatchCompare',
      component: () => import( /* webpackChunkName: "MatchCompare" */ './views/MatchCompare.vue'),
    },

    {
      path: '/matches/:id/details',
      name: 'MatchDetails',
      component: () => import( /* webpackChunkName: "MatchDetails" */ './views/MatchDetails.vue'),
    },
    {
      path: '/matches/:id/:round?',
      name: 'MatchSingle',
      component: () => import( /* webpackChunkName: "MatchSingle" */ './views/MatchSingle.vue'),
    },

    {
      path: '/rounds/:id',
      name: 'RoundSingle',
      component: () => import( /* webpackChunkName: "RoundSingle" */ './views/RoundSingle.vue'),
    },

    {
      path: '/collections',
      name: 'CollectionList',
      component: () => import( /* webpackChunkName: "CollectionList" */ './views/CollectionList.vue'),
      meta: {
        requiresAuth: []
      }
    },
    {
      path: '/collections/:id',
      name: 'CollectionSingle',
      component: () => import( /* webpackChunkName: "CollectionSingle" */ './views/CollectionSingle.vue'),
    },
    {
      path: '/teams',
      name: 'TeamList',
      component: () => import( /* webpackChunkName: "TeamList" */ './views/TeamList.vue'),
      meta: {
        requiresAuth: []
      }
    },
    {
      path: '/teams/:id',
      name: 'TeamSingle',
      component: () => import( /* webpackChunkName: "TeamSingle" */ './views/TeamSingle.vue'),
      meta: {
        requiresAuth: []
      }
    },
    {
      path: '/users/:id',
      name: 'UserSingle',
      component: () => import( /* webpackChunkName: "UserSingle" */ './views/UserSingle.vue'),
    },
    {
      path: '/users/:id/edit',
      name: 'UserEdit',
      component: () => import( /* webpackChunkName: "UserEdit" */ './views/UserEdit.vue'),
      meta: {
        requiresAuth: []
      }
    },
    {
      path: '/players/:id',
      name: 'PlayerSingle',
      component: () => import( /* webpackChunkName: "PlayerSingle" */ './views/players/PlayerSingle.vue'),
    },
    {
      path: '/search',
      name: 'SearchResult',
      component: () => import( /* webpackChunkName: "SearchResult" */ './views/SearchResult.vue'),
      meta: {
        requiresAuth: []
      }
    },
    {
      path: '/notifications',
      name: 'Notifications',
      component: () => import( /* webpackChunkName: "Notifications" */ './views/Notifications.vue'),
      meta: {
        requiresAuth: []
      }
    },
    {
      path: '/about',
      name: 'About',
      meta: { layout: 'marketing' },
      component: () => import( /* webpackChunkName: "About" */ './views/About.vue')
    },
    {
      path: '/upgrade',
      name: 'Upgrade',
      component: () => import( /* webpackChunkName: "Upgrade" */ './views/Upgrade.vue'),
      meta: {
        requiresAuth: []
      }
    },
    {
      path: '/account',
      name: 'Account',
      component: () => import( /* webpackChunkName: "Account" */ './views/Account.vue'),
      meta: {
        requiresAuth: [],
        transition: 'fade'
      }
    },
    {
      path: '/features/embed',
      name: 'FeaturesEmbed',
      meta: { layout: 'marketing' },
      component: () => import( /* webpackChunkName: "FeaturesEmbed" */ './views/front/FeaturesEmbed.vue')
    },
    {
      path: '/features/integration',
      name: 'FeaturesIntegration',
      meta: { layout: 'marketing' },
      component: () => import( /* webpackChunkName: "FeaturesIntegration" */ './views/front/FeaturesIntegration.vue')
    },
    {
      path: '/features/live',
      name: 'FeaturesLive',
      meta: { layout: 'marketing' },
      component: () => import( /* webpackChunkName: "FeaturesLive" */ './views/front/FeaturesLive.vue')
    },
    {
      path: '/features/search',
      name: 'FeaturesSearch',
      meta: { layout: 'marketing' },
      component: () => import( /* webpackChunkName: "FeaturesSearch" */ './views/front/FeaturesSearch.vue')
    },
    {
      path: '/features/social',
      name: 'FeaturesSocial',
      meta: { layout: 'marketing' },
      component: () => import( /* webpackChunkName: "FeaturesSocial" */ './views/front/FeaturesSocial.vue')
    },
    {
      path: '/features/guides',
      name: 'FeaturesGuides',
      meta: { layout: 'marketing' },
      component: () => import( /* webpackChunkName: "FeaturesGuides" */ './views/front/FeaturesGuides.vue')
    },
    {
      path: '/integracoes/chrome',
      name: 'ChromeExtension',
      meta: { layout: 'marketing' },
      component: () => import( /* webpackChunkName: "ChromeExtension" */ './views/front/ChromeExtension.vue')
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicy',
      meta: { layout: 'marketing' },
      component: () => import( /* webpackChunkName: "PrivacyPolicy" */ './views/content/PrivacyPolicy.vue')
    },
  ]
})

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start()
  }
  next()
})

router.afterEach((to, from) => {
  // Complete the animation of the route progress bar.
  NProgress.done()
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    } else {
      store.dispatch('redirectingToLogin')
      router.push('/login')
    }

  } else {
    next()
  }

  const { requiresAuth } = to.meta;
  const currentUser = store.getters.user;
  const isLoggedIn = store.getters.isLoggedIn;



  if (requiresAuth) {
    if (!isLoggedIn) {
      // not logged in so redirect to login page with the return url
      return next({ path: '/login', query: { returnUrl: to.path } });
      // store.dispatch('redirectingToLogin')
      // router.push('/login')
    }

    // TODO check if route is restricted by role
    if (requiresAuth.length) {
      if(requiresAuth[0] == 'admin') {
        // role not authorised so redirect to home page
        if(!requiresAuth.includes(currentUser.role))
          return next({ path: '/' });
      }
    }
  }

  next();
})

export default router;
