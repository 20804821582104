<template>
  <div id="default">
    <b-navbar class="main-navbar" toggleable="md" type="dark" variant="dark">
      <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>

      <b-navbar-brand
        class="navbar-logo"
        to="/"
        exact
        v-b-tooltip.hover
        :title="$t('NAV_HEADER.back_to_home')"
      >
        <b-img src="/fightback-logo-white.png" height="40"></b-img>
      </b-navbar-brand>

      <b-collapse is-nav id="nav_collapse">
        <b-navbar-nav>
          <b-nav-item v-if="isLoggedIn" to="/my_matches">{{
            $t("NAV_HEADER.navigation_links.my_matches")
          }}</b-nav-item>
          <b-nav-item v-if="isLoggedIn" to="/matches">{{
            $t("NAV_HEADER.navigation_links.matches")
          }}</b-nav-item>
          <b-nav-item v-if="isLoggedIn" to="/collections">
            <i class="fas fa-folder"></i>
            {{ $t("NAV_HEADER.navigation_links.collections") }}
          </b-nav-item>
          <b-nav-item to="/teams" v-if="isLoggedIn && isAdmin && false">{{
            $t("NAV_HEADER.navigation_links.teams")
          }}</b-nav-item>
          <b-nav-item v-if="isLoggedIn && false" to="/search">
            <i class="fas fa-search"></i>
            {{ $t("NAV_HEADER.navigation_links.search") }}
          </b-nav-item>
          <b-nav-item
            v-if="
              isLoggedIn && currentUser.subscription_package.package == 'free'
            "
            to="/upgrade"
          >
            <!-- <i class="fas fa-search"></i> -->
            {{ $t("NAV_HEADER.navigation_links.upgrade") }}
          </b-nav-item>
        </b-navbar-nav>

        <b-navbar-nav class="ml-auto">
          <b-nav-item v-if="isLoggedIn" :to="{ name: 'Notifications' }">
            <!-- {{$t("NAV_HEADER.navigation_links.notifications")}} -->
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-bell"
              viewBox="0 0 16 16"
            >
              <path
                d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"
              />
            </svg>
          </b-nav-item>

          <b-nav-item-dropdown right v-if="false">
            <template slot="button-content">
              <em>{{ $i18n.locale }}</em>
            </template>
            <b-dropdown-item
              @click.stop="changeLang(lang)"
              v-for="(lang, i) in langs"
              :key="`Lang${i}`"
              >{{ lang }}</b-dropdown-item
            >
          </b-nav-item-dropdown>

          <b-nav-item-dropdown right v-if="isLoggedIn" class="avatar-dropdown">
            <template slot="button-content">
              <div class="nav-avatar">
                <b-img
                  fluid
                  :src="currentUser.avatar.url"
                  v-if="currentUser && currentUser.avatar"
                />
              </div>
              <em v-if="currentUser && currentUser.display_name">{{
                currentUser.display_name
              }}</em>
            </template>
            <b-dropdown-item
              :to="{ name: 'UserSingle', params: { id: currentUser.id } }"
              v-if="currentUser && false"
              >{{
                $t("NAV_HEADER.navigation_links.my_profile")
              }}</b-dropdown-item
            >
            <b-dropdown-item
              :to="{ name: 'UserEdit', params: { id: currentUser.id } }"
              v-if="currentUser"
              >{{
                $t("NAV_HEADER.navigation_links.edit_profile")
              }}</b-dropdown-item
            >
            <b-dropdown-item :to="{ name: 'Account' }" v-if="currentUser">{{
              $t("NAV_HEADER.navigation_links.plan")
            }}</b-dropdown-item>
            <b-dropdown-item v-on:click.prevent="logout">{{
              $t("NAV_HEADER.navigation_links.logout")
            }}</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item to="/login" v-else>{{
            $t("NAV_HEADER.navigation_links.login")
          }}</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <div v-if="isMobile">
      <div class="container mt">
        <b-alert show variant="info">{{
          $t("UTILITY.better_on_mobile")
        }}</b-alert>
      </div>
    </div>

    <notifications group="all" />

    <b-toast
      title="Novo update"
      id="pwa-update-toast"
      variant="info"
      solid
      v-model="updateExists"
    >
      <p>nova versão do site disponível</p>
      <b-btn size="sm" variant="outline-success" @click="refreshApp"
        >atualizar agora</b-btn
      >
    </b-toast>

    <vue-page-transition name="fade-in-up">
      <slot />
    </vue-page-transition>

    <footer class="app-footer">
      <div class="container">
        <div class="col-12 col-md">
          <img
            class="mb-2"
            src="/icons/favicon-32x32.png"
            alt
            width="32"
            height="32"
          />
          <small class="d-block mb-3">&copy; 2017-2021</small>
        </div>
        <div class="col-6 col-md">
          <h5>{{ $t("FOOTER.to_players.title") }}</h5>
          <ul class="list-unstyled text-small">
            <li>
              <a class="text-muted" href="/integracoes/chrome">{{
                $t("FOOTER.to_players.gc")
              }}</a>
            </li>
            <li>
              <a class="text-muted" href="/integracoes/chrome">{{
                $t("FOOTER.to_players.hltv")
              }}</a>
            </li>
          </ul>

          <h5>{{ $t("FOOTER.about.title") }}</h5>
          <ul class="list-unstyled text-small">
            <li>
              <a class="text-muted" href="/about">{{
                $t("FOOTER.about.contact")
              }}</a>
            </li>
            <li>
              <a class="text-muted" href="/privacy-policy">{{
                $t("FOOTER.about.privacy")
              }}</a>
            </li>
          </ul>
        </div>
      </div>
    </footer>

    <b-button
      target="_blank"
      href="https://discord.gg/Fux455R"
      id="supportWidget"
    >
      <i class="far fa-question-circle"></i>
      <span>{{ $t("SUPPORT_WIDGET.support_label") }}</span>
    </b-button>
  </div>
</template>

<style lang="sass">
.fade-in-up-leave-to
  transition: opacity .2s

.fade-in-up-enter-to
  animation-duration: .3s !important

.alert.alert-page-title
  position: relative
  .alert-actions
    position: absolute
    top: 10px
    right: 10px
    i
      font-size: 24px
      cursor: pointer
// background: #272f4a;

.page-title
  margin: 20px 0 40px
  padding: 10px 0
  border-bottom: 2px solid #ddd
  h1, h2, h3, h4, h5, h6
    margin: 0

.app-footer
  padding: 2rem 0
  margin-top: 4rem
  // font-size: 85%
  // text-align: center
  background-color: #000
  color: white

.navbar.bg-danger
  background: #ED2024 !important
.navbar.bg-dark
  background: #000 !important
.navbar-logo
  opacity: .5
  transition: all .2s
  &:hover
    opacity: 1

.avatar-dropdown
  .nav-link.dropdown-toggle
    display: flex
    align-items: center
    .nav-avatar
      display: inline-block
      width: 24px
      height: 24px
      margin-right: 10px
      overflow: hidden
      border-radius: 250%
      position: relative
      border: 1px solid #ccc
      img
        width: 100%
        height: 100%

.main-navbar .nav-link,
.main-navbar .dropdown-item
  font-family: $font
  font-weight: 700
.page-header
  .page-actions
    // display: flex

.section-title
  border-bottom: 1px solid #f1f1f1
  margin-bottom: 10px
  padding-bottom: 0px
  color: #888
  h5
    text-transform: uppercase
    font-size: 14px
    margin-bottom: 0
    font-weight: bold
  h4
    text-transform: uppercase
    font-size: 18px
    margin-bottom: 0
    font-weight: bold

#supportWidget
  position: fixed
  bottom: 10px
  right: 10px
  background: #666
  display: flex
  padding: 4px
  border-radius: 250px
  color: white
  align-items: center
  transition: all .2s
  border: 0
  outline: none
  &:focus
    box-shadow: none
  i
    font-size: 24px
  span
    font-size: 16px
    margin: 0 6px 0 4px
  &:hover
    background: $danger
#supportModal
  .support-icons
    display: flex
    flex-wrap: wrap
    align-items: center
    justify-content: flex-start
    a
      display: inline-flex
      border: 3px solid #888
      width: auto
      padding: 4px 11px 4px 12px
      border-radius: 250px
      color: #888
      align-items: center
      margin: 4px 10px
      transition: all .2s
      font-weight: bold
      &.discord:hover
        text-decoration: none
        color: #7289DA
        border: 3px solid #7289DA
      &.whatsapp:hover
        text-decoration: none
        color: #25d366
        border: 3px solid #25d366
      i
        font-size: 22px
        margin-right: 5px
</style>

<script>
import updateMixin from "@/mixins/update.mixin";
export default {
  name: "DefaultLayout",
  metaInfo: {
    bodyAttrs: {
      class: ["default-layout"],
    },
  },
  computed: {},
  mixins: [updateMixin],
  data() {
    return {
      subscription: {},
      userChannelSubscription: {},
      langs: ["pt_BR", "en"],
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/");
    },

    changeLang(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem("locale", lang);
      document.querySelector("html").setAttribute("lang", lang);
    },

    initialize() {
      var self = this;
      if (localStorage.getItem("locale")) {
        this.changeLang(localStorage.getItem("locale"));
      }
      if (this.isLoggedIn) {
        this.subscription = this.$cable.subscriptions.create(
          { channel: "NotificationChannel" },
          {
            received: function (data) {
              self.$notify({
                group: "all",
                text: "Você tem uma nova notificação",
                type: "success",
                duration: -1000,
              });
            },
          }
        );

        this.userChannelSubscription = this.$cable.subscriptions.create(
          { channel: "UserChannel" },
          {
            received: function (data) {
              self.$http.get("users/current_user.json").then((response) => {
                self.$store.dispatch("updateUserProfile", response.data);
              });
            },
          }
        );
      }
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>
