module.exports = {
  en: {
    NAV_HEADER: {
      back_to_home: "back to dashboard",
      navigation_links: {
        my_matches: "My Matches",
        matches: "Public Matches",
        collections: "Collections",
        teams: "Teams",
        search: "Search",
        notifications: "Notifications",
        my_profile: "My Profile",
        edit_profile: "Edit profile",
        plan: "My plan",
        logout: "Logout",
        login: "Login",
        upgrade: 'Plans / Upgrade your account'
      }
    },
    FOOTER: {
      to_players: {
        title: "To players",
        gc: "Integration with Gamersclub",
        hltv: "Integration with HLTV"
      },
      to_teams: {
        title: "To teams",
        live: "Live review session"
      },
      to_companies: {
        title: "To companies",
        embed: "Embed Player",
        integration: "Post-match API integration",
        live: "Live review"
      },
      about: {
        title: "About",
        about: "About us",
        contact: "Contact",
        jobs: "Work with us",
        privacy: "Privacy"
      }
    },
    PAGE_LOGIN: {
      main_title: "Level up on Counter-Strike",
      main_subtitle: "Learn watching how you, your team and the PROs plays",
      integrations_title: "Works it",
      login_title: "Login",
      login_submit: "Login",
      email_placeholder: "Your email",
      password_placeholder: "Your password",
      non_user_title: "Don't have an account?",
      non_user_signup_now: "Signup Now",
      features: {
        title: "We help you understand",
        clutch: {
          title: "Clutch Situations",
          subtitle: "How to play in (dis)advantage"
        },
        grenades: {
          title: "Effective Grenades",
          subtitle: "am I just spending cash?"
        },
        playstyle: {
          title: "Agressive x Defensive",
          subtitle: "Which one works better to me?"
        }
      },
      search: {
        title: "FIGHTBACK Search",
        subtitle: "Find those rounds you need",
        line1: "Com os filtros você consegue buscar exatamente o round que procura.",
        line2: "Escolha o mapa, se é eco, forçado, e navegue por tags."
      },
      extension: {
        title: "Google Chrome Extension",
        subtitle: "Replay your CS:GO matches right on your browser",
        works: "Works with all websites you already plays on",
        line1: "Você não precisa baixar a demo dos sites e upar no FIGHTBACK.",
        line2: "Com a extensão tudo fica mais fácil.",
        see_more: "Know more"
      },
      comments: {
        title: "Comments",
        subtitle: "Analysing is easier in groups",
        line1: "With our comments system everybody learns.",
        line2: "Discuss with your team, friends and the whole FIGHTBACK\'s community."
      },
      plans: {
        title: "Plans",
        subtitle: "We've made plans to every use case"
      }
    },
    MATCHES: {
      actions: {
        edit_match: 'edit match',
        delete_match: 'delete match',
        add_to_collection: 'save to Collection',
        share_match: 'share',
        download_match: 'download demo'
      },
      parsing_alert: {
        title: 'We\'re parsing this match',
        subtitle: 'Refresh this page in few minutes...'
      },
      tabs: {
        dashboard: 'Dashboard',
        economy: 'Economy',
        watch: 'Watch Rounds'
      },
      economy: {
        total_spent: 'money spent',
        start_money: 'start equip.',
        economy_vs_rounds: 'economy X rounds won',
      },
      share_modal: {
        title: 'share',
        email_placeholder: 'fill with user\'s email who you wants to share',
        submit: 'share'
      },
      tags_modal: {
        title: 'edit tags',
        common_tags: 'common tags',
        submit: 'save'
      },
      edit_modal: {
        title: 'editing',
        name_placeholder: 'name',
        unique_code_placeholder: 'unique code',
        is_public_label: 'Is it public?',
        submit: 'Save'
      }
    },
    MY_MATCHES: {
      title: "My matches",
      send_match: "Send a match",
      total_sent_matches: "Sent matches",
      remaining_matches: 'You have {count} matches on current month',
      matches_full: "You have reach the matches maximum for this month. Upgrade."
    },
    MATCH_NEW: {
      title: 'Send a match',
      subtitle: 'Upload your demo <strong>.dem</strong>'
    },
    ROUND_SINGLE: {
      report_round_title: 'Problemas ao ver esse round?',
      report_round: 'reportar',
      related_rounds: 'rounds relacionados',
      comments: {
        title: 'comentários',
        no_comments: 'nenhum comentário, seja o primeiro',
        comment_placeholder: 'Comente aqui...',
        submit: 'comentar',
        logged_out_warning: 'você precisa estar logado para comentar'
      },
      actions: {
        back_to_match: 'voltar a partida',
        add_to_collection: 'adicionar a Collection',
        share: 'compartilhar'
      },
      report_modal: {
        modal_title: 'reportar round',
        title: 'problemas com esse round?',
        subtitle: 'Caso não esteja conseguindo rever este round ou ocorra algum outro problema, digite o motivo no campo abaixo e clique em reportar',
        text_placeholder: 'digite aqui o que está acontecendo',
        submit: 'reportar'
      },
      share_modal: {
        modal_title: 'compartilhe este round',
        title: 'embed em qualquer site',

      }
    },
    COLLECTIONS: {
      title: "Collections",
      subtitle: "Usando Collections você consegue organizar suas partidas e rounds favoritos em uma playlist.",
      create: "criar uma collection",
      modal: {
        title: 'Criar Collection',
        name: 'Nome da Collection',
        name_placeholder: 'Escolha um nome',
        description: 'Descrição',
        description_placeholder: 'Do que se trata essa collection? ex: rounds pistol em um mapa? jogos de um campeonato? rounds forçados?',
        is_public: 'Pública?',
        is_public_description: 'selecionando estará disponível para todos os usuários do FIGHTBACK, caso contrário apenas para você e quem convidar',
        is_public_label: 'Collection Pública',
        submit: 'Criar'
      },
      table_title: 'Collections do FIGHTBACK.club',
      created_by: 'criado por'
    },
    TEAMS: {
      title: 'Times',
      create_team: 'criar time',
      modal: {
        title: 'criando time',
        name_placeholder: 'informe um nome',
        submit: 'criar'
      }
    },
    SEARCH: {
      title: 'Busca',
      subtitle: 'É aqui onde tudo faz sentido. Filtre por mapas ou tags em TODAS AS PARTIDAS',
      no_rounds_found: 'nenhum round encontrado',
      collection_modal: {
        title: 'adicionar a uma Collection',
        match: 'adicionar partida a Collection',
        match_round: 'adicionar round a Collection',
        name_placeholder: 'digite um nome para identificar',
        submit: 'Salvar'
      },
      filters: {
        my_matches_only: "Buscar apenas em minhas partidas",
        tags: "filtre por tags",
        tags_title: "clique para adicionar uma tag aos filtros",
        maps: "filtre por mapa",
        maps_title: "clique em um mapa abaixo para filtrar todos os rounds apenas no mapa selecionado",
        economy: "filtre por economia",
        economy_title: "clique em um mapa abaixo para filtrar por economia no round",
        trouble: "de qual time?",
        trouble_title: "clique em um mapa abaixo para filtrar pelo time nesta economia"
      }
    },
    NOTIFICATIONS: {
      title: 'Notificações',
    },
    USERS: {
      about: 'Sobre',
      collections: 'Collections',
      latest_rounds: 'Últimos rounds jogados',
      change_avatar: 'alterar avatar',
      edit_title: 'Edite seu perfil',
      steam_connected: 'conectado com steam',
      steam_connect: 'conectar com steam',
      form: {
        name: 'Nome',
        name_placeholder: 'Informe seu nome',
        nick: 'Apelido / Nick',
        nick_placeholder: 'informe seu nick',
        were_found_us: 'Onde nos conheceu?',
        were_found_us_placeholder: 'site? google? redes sociais? outro?',
        profile_extra_a: 'Com quem irá usar o FIGHTBACK.club?',
        alone: 'Sozinho',
        with_team: 'Com meu time',
        submit: 'Salvar'
      }
    },
    ACCOUNT: {
      title: 'Minha conta',
      no_plan: 'Você não tem nenhum plano ativo',
      current_plan: 'Atualmente você está no plano <strong>{package}</strong>',
      expires_at: 'Expira em {at}',
      needs_update: {
        title: 'Antes de contratar um plano você precisa preencher detalhes do seu perfil',
        subtitle: 'Completar cadastro',
        modal: {
          title: 'Dados pessoais para Nota Fiscal',
          cpf: 'seu cpf',
          cellphone: 'seu celular',
          submit: 'salvar e prosseguir'
        }
      },
      no_premium: {
        title: 'Seja PREMIUM e tenha acesso imediato a',
        private_upload: 'Upload de <strong>100 partidas privadas</strong> por mês',
        private_share: 'Compartilhar partidas privadas com outros usuários (seu time por exemplo)',
        collections: '<strong>Collections</strong> para você salvar todos os rounds e partidas em listas',
        cta: 'Seja PREMIUM agora por R$ 29,90'
      },
      subscriptions: {
        title: 'suas assinaturas',
        created_at: 'Contratado em',
        status: 'Situação',
        ends_at: 'Encerra em',
        pay_now: 'Pagar agora'
      }
    },
    HOME: {
      welcome_alert: {
        title: 'Bem-vindo ao FIGHTBACK',
        line1: 'Estamos aqui para te ajudar a melhorar no CS:GO',
        line2: 'Separamos algumas partidas para você assistir'
      },
      upload_alert: {
        title: 'Veja suas partidas',
        line1: 'Para começar, envie a demo de uma partida',
        submit: 'Enviar uma partida'
      }
    },
    ONBOARDING: {
      title: 'Bem-vindo ao FIGHTBACK.club',
      line1: 'Estamos aqui para ajudar você e seu time a melhorar no CS:GO.',
      line2: 'Alguns detalhes antes de continuar:',
      line3: 'Ajuda e novidades é pelo <a href="https://discord.gg/8HJMgG4" target="_blank">nosso Discord</a>. Entra lá :)',
      line4: 'Preencha seu perfil e conecte com a Steam - teremos novidades em breve ;)',
      line5: 'De coração, obrigado mais uma vez.'
    },
    SUPPORT_WIDGET: {
      support_label: 'support',
      whatsapp_label: 'Send a message on Whatsapp',
      discord_label: 'Talk with us on Discord',
      modal_title: 'Needs help?'
    },
    UTILITY: {
      better_on_mobile: "Nosso site funciona melhor ainda no computador",
      unnamed: 'sem nome',
      match: "Partida",
      matches: "Partidas",
      map: "Mapa",
      added_at: "Adicionado em",
      round: 'Round',
      winner: 'Vencedor',
      timeline: 'Timeline',
      tags: 'Tags',
      see_match: 'ver partida',
      here: 'aqui',
      latest_comments: 'últimos comentários',
      by: 'por',
      at: 'em',
      trending_rounds: 'rounds em alta',
      team_a: 'team A',
      team_b: 'team B',
      share: 'compartilhar'
    },

    REASON_CODES: {
      forgot_password_used: "Este token já foi utilizado"
    }
  }
}
